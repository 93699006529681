@font-face {
    font-family: "Tilt";
    src: url('../../fonts/Tilt-Neon.ttf');
  }

.footer {
    width: 100%;
    height: 300px;
    background-color: #893ca6;
    display: flex;
    justify-content: center;
}

@media (max-width: 850px) {
    .footer {
        height: 200px;
    }
  }

  @media (max-width: 400px) {
    .footer {
        height: 150px;
    }
  }
  
.footer__information-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.footer-img {
    background-color: transparent;
    top:10px;
    animation: clock 1s;
    animation-iteration-count:infinite;
    animation-direction: alternate;
}

@media (max-width: 850px) {
    .footer-img {
        width: 70px;
        height: 70px;
    }
  }
  
  @media (max-width: 400px) {
    .footer-img {
        width: 50px;
        height: 50px;
    }
  }

.footer__information {
    display: flex;
    height: 100%;
    align-items: center;
}

@media (max-width: 400px) {
    .footer__information {
        flex-direction: column;
        height: 50%;
        align-items: flex-start;
    }
  }

.footer__text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.footer__title {
	font-size: 1.1rem;
	font-family: "Tilt";
	font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

.footer__copy {
	font-size: 1.1rem;
	font-family: "Tilt";
	font-weight: 300;
    letter-spacing: 2px;
}

@media (max-width: 850px) {
    .footer__title {
        font-size: 1rem;
    }
    .footer__copy {
        font-size: 1rem;
    }
  }
  
  @media (max-width: 400px) {
    .footer__title {
        font-size: 0.7rem;
    }
    .footer__copy {
        font-size: 0.7rem;
    }
  }

.footer__list-item {
    margin-top: 20px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    font-size: 1.1rem;
	font-family: "Tilt";
	font-weight: 300;
    letter-spacing: 1px;
}

@media (max-width: 850px) {
    .footer__list-item {
        margin-top: 10px;
        font-size: 0.8rem;
    }
  }
  
  @media (max-width: 400px) {
    .footer__list-item {
        margin-top: 8px;
    }
  }

.footer__item-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
}

.footer__link-img{
    margin-right: 10px;
}

@media (max-width: 850px) {
    .footer__link-img{
        width: 25px;
        height: 25px;
        margin-right: 0;
    }
   .footer__link-text {
    display: none;
   }
  }
  

.footer__list-item:hover {
    opacity: 1;
}

@keyframes clock {
    0% {

      transform: translateY(-30px);
      }
    100% {

      transform: translateY(0);
      }
  }