.delete-modal {
    position: absolute;
    top: 100px;
    left: 100px;
    right: 100px;
    bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	-webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  @media (max-width: 600px) {
    .delete-modal {
      top: 50px;
      left: 50px;
      right: 50px;
      bottom: 50px;
    }
  }
  @media (max-width: 400px) {
    .delete-modal {
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
    }
  }
  .delete-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	z-index: 5;
	-webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  .modal__title {
    padding: 10px;
    border-radius: 10px;
	font-size: 1.8rem;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 300;
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;
  background-color: #ffffffd4;
  }

  @media (max-width: 600px) {
    .modal__title {
    font-size: 1.4rem;
    }
  }

  @media (max-width: 500px) {
    .modal__title {
    font-size: 1.2rem;
    padding: 5px;
    }
  }

  .modal__button-wrapper {
	width: 350px;
	display: flex;
	justify-content: space-between;
  }

  @media (max-width: 600px) {
    .modal__button-wrapper {
      width: 280px;
    }
  }

  @media (max-width: 500px) {
    .modal__button-wrapper {
    width: 230px;
    }
  }


  .modal__button {
	border: none;
	border-radius: 4px;
	font-size: 1.4rem;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 300;
  background-color: #ffffffd4;
  padding: 5px;
  box-sizing: border-box;
  }

  @media (max-width: 600px) {
    .modal__button {
    font-size: 1.2rem;
    }
  }

  @media (max-width: 500px) {
    .modal__button {
    font-size: 1rem;
    padding: 3px;
    }
  }

  .modal__button:hover {
	cursor: pointer;
  }

  .city__list-modal {
    position: absolute;
    top: 0;
    left: 130px;
    right: 130px;
    bottom: 0;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
    background-color: #ffffffd4;
    overflow-y: scroll;
    z-index: 11;
    animation: modal 0.2s;
  }

  @media (max-width: 700px) {
    .city__list-modal {
      left: 50px;
      right: 50px;

    }
  }

  @media (max-width: 500px) {
    .city__list-modal {
      left: 0;
      right: 0;

    }
  }
  .city__list-overlay {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
	  z-index: 5;
	  -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  @keyframes modal {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0);
    }
  }