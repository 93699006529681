@font-face {
    font-family: "Tilt";
    src: url('../src/fonts/Tilt-Neon.ttf');
  }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.invisible {
	display: none;
}

.transparent {
	color: transparent;
}

.container {
	width: 90%;
	position: relative;
}

@media (max-width: 500px) {
	.container {
		width: 90%;
		position: relative;
	}
}


.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #f29191;
	min-height: calc(100vh - 60px - 300px);
	padding-bottom: 50px;
	z-index: 0;
}
@media (max-width: 850px) {
    .main {
        min-height: calc(100vh - 60px - 200px);
    }
  }

  @media (max-width: 400px) {
    .main {
        min-height: calc(100vh - 60px - 150px);
    }
  }

.top {
	z-index: 200;
}

.refresh__button {
	background-color: #f7dfdf;
	border: 2px solid #000;
	border-radius: 5px;
	position: absolute;
	top: 10px;
	right: 0px;
	width: 40px;
	height: 40px;
	padding: 2px;
	z-index: 5;
}
.refresh__button-icon {
	width: 100%;
	height: 100%;

}

@media (max-width:500px) {
	.refresh__button {
		width: 30px;
		height: 30px;
	}
}

.refresh__button:hover {
cursor: pointer;
}

.stiky {
	height: 100vh !important;
	overflow: hidden;
}

.app {
	position: relative;
}

.modal {
	width: 150px;
	height: 150px;
	position: absolute;
	right: 100px;
	z-index: 6;
	background-color: #fff;
	border-radius: 10px;
	color: #000;
	padding: 10px;
	box-sizing: border-box;
	border: 2px solid black;
}
@media (max-width:500px) {
	.modal {
		width: 100px;
		height: 100px;
		right: 50px;
	}
}
.modal__text {
	font-family: "Tilt";
	font-size: 1.5rem;
	text-align: center;
}
@media (max-width:500px) {
	.modal__text {
		font-size: 1rem;
	}
}