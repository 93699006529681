.blur {
    min-height: 100vh;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 60px;
    top: 50px;
    width: 100%;
    z-index: 90;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    animation: blur 0.5s;
}

.blur__button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 30px;
    top: 20px;
}
.blur__button:hover {
    cursor: pointer;
}