.react-time-picker {
    display: inline-flex;
    position: relative;
    text-align: center  !important;
  }
  
  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  
  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
  
  }
  
  .react-time-picker__inputGroup {
    min-width: calc((4px * 1) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    border: 1px solid black;
    border-radius: 3px;
    background-color: #fff;
  }
  
  .react-time-picker__inputGroup__divider {
    white-space: pre;
    font-size: 20px;
    font-weight: 300 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 1px;
  }

  @media (max-width: 400px) {
    .react-time-picker__inputGroup__divider {
      font-size: 1rem !important;
      margin-right: 3px;
    } 
  }
  
  .react-time-picker__inputGroup__divider,
  .react-time-picker__inputGroup__leadingZero {
    display: inline-block;
    font-size: 20px;
    padding-top: 1px;
    font-weight: 300 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 1px;
    margin-bottom: 1.3px;
  }

  @media (max-width: 400px) {
    .react-time-picker__inputGroup__leadingZero {
      font-size: 1rem;
      padding-bottom: 4px;
    }
  }
  
  .react-time-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    font-size: 20px;
    font-weight: 300 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px !important;
  }
  
  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
  }
  
  .react-time-picker__inputGroup__amPm {
    font: inherit;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
    font-size: 20px !important;
    font-weight: 300 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  }

  @media (max-width:400px) {
    .react-time-picker__inputGroup__amPm {
      font-size: 1rem !important;
     }
   }
  
  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  
  .react-time-picker__button:enabled {
    cursor: pointer;
  }
  
  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7;
  }
  
  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
  }
  
  .react-time-picker__button svg {
    display: inherit;
  }
  
  .react-time-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
    z-index: 1;
  }
  
  .react-time-picker__clock--closed {
    display: none;
  }
  .react-time-picker__inputGroup__leadingZero {
    margin-top: 3.5px;
    margin-right: 1.5px;
  }



  .react-time-picker__inputGroup__minute {
    width: 25px !important;
  }

 .react-time-picker__inputGroup__hour {
  font-size: 20px;
 }

 @media (max-width:400px) {
  .react-time-picker__inputGroup__hour {
    font-size: 1rem;
   }
 }


 .react-time-picker__inputGroup__minute {
  font-size: 20px ;
 }

 @media (max-width:400px) {
  .react-time-picker__inputGroup__minute {
    font-size: 1rem;
   }
 }
 /* .react-time-picker__inputGroup__input {
  width: 20px;
 } */
