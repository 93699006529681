.header {
    height: 60px;
    background-color: #751b4b;
    display: flex;
    flex-direction: column;
	align-items: center;
    justify-content: center;
    -webkit-box-shadow: 2px -1px 13px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px -1px 13px 0px rgba(0,0,0,0.75);
    box-shadow: 2px -1px 13px 0px rgba(0,0,0,0.75);
    position: relative;
}

.header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__list {
    margin-top: 10px;
    margin-bottom: 10px;
}

.header__list-item {
    border: 1px solid #000;
	border-radius: 4px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__list-button {
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
}

.header__list-button:hover {
cursor: pointer;
}

.header__item-icon {
    margin-right: 10px;
}


@media (max-width: 850px) {
    .header__item-icon {
        width: 25px;
        height: 25px;
    }
}
@media (max-width: 400px) {
    .header__item-icon  {
        width: 20px;
        height: 20px;
    }
}

.header__list-text {
	font-size: 1.2rem;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 300;
    padding: 5px;
    letter-spacing: 1.5px;
}

@media (max-width: 850px) {
    .header__list-text {
        font-size: 1rem;
    }
}



.header__add {
    display: flex;
    width: 85%;
}

.header__buttons-wrapper {
    display: flex;
    align-items: center;
}

.header__button-add {
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    padding: 0;
}

.header__button-close {
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    padding: 0;
}

@media (max-width: 700px) {
    .header__button-close-img{
        width: 30px;
        height: 30px;
    }
    .header__button-close {
        width: 30px;
    }
}


.header__input {
   width: 100%;
   height: 40px;
   font-size: 1.2rem;
   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
   font-weight: 300;
   border-radius: 10px;
   padding-left: 10px;
   border: none;
   border-bottom: 1px solid grey;
   animation: input 0.3s;
}
@media (max-width: 850px) {
    .header__input {
        font-size: 1rem;
    }
}
@media (max-width: 700px) {
    .header__icon {
        width: 35px;
        height: 35px;
    }
    .header__input {
        padding: 5px;
        height: 25px;
        margin-right: 10px;
        margin-left: 10px;
    }
}


.header__input:focus {
    outline: none;
 }

.header__button-add:hover {
    cursor: pointer;
}

.header__menu-button {
    padding: 0;
    background-color: transparent;
    border: none;
    margin-left: 10px;
    width: 40px;
    height: 40px;
}


.header__menu-button:hover {
   cursor: pointer;
}

.input__text-noResult {
    font-size: 1.2rem;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 400;
    letter-spacing: 1.5px;
    text-align: center;
    margin-top: 50px;
}


@keyframes input {
    from { opacity: 0;}
    to {opacity: 1;}
  }