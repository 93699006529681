@font-face {
    font-family: "Tilt";
    src: url('../../fonts/Tilt-Neon.ttf');
  }

.sidebar {
    width: 400px;
    height: calc(100vh - 60px);
    position: absolute;
    right: 0;
    top:60px;
    bottom:  0;
    background-color: #f7cdcd;
    z-index: 10;
    overflow-y: scroll;
    animation: sidebar 0.2s;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 1s;
}

@media (max-width:500px) {
    .sidebar {
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.sidebar__title {
    margin-top: 10px;
    margin-left: 5px;
    font-size: 1.8rem;
	font-family: "Tilt";
	font-weight: 300;
    padding: 5px;
    letter-spacing: 1.5px;
}

@media (max-width: 850px) {
    .sidebar__title {
        font-size: 1.3rem;
    }
}

@media (max-width: 400px) {
    .sidebar__title {
        font-size: 1.2rem;
    }
}


.sidebar__content {
    width: 100%;
    padding: 20px;
    
}

.sidebar__button {
    background-color: #751b4b;
    color: #fff;
    font-size: 1.4rem;
	font-family: "Tilt";
    border: none;
    width: 90%;
    height: 50px;
    border-radius: 5px;
	font-weight: 300;
    padding: 2px;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
}

@media (max-width: 850px) {
    .sidebar__button {
        font-size: 1rem;
    }

}
@media (max-width: 400px) {
    .sidebar__button {
        font-size: 0.9rem;
    }
}


.sidebar__button:hover {
    cursor: pointer;
}

.sidebar__list {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
	font-weight: 300;
    padding: 5px;
    letter-spacing: 1.5px;
    overflow: hidden;
    animation: list 0.5s;
    transition: all 1s;
}

.sidebar__item {
    width: 90%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    box-sizing: border-box;
}

@media (max-width: 500px) {
    .sidebar__item {
        margin-bottom: 10px;
    }
}

.sidebar__item-button {
    background-color: #a33670;
    color: #fff;
    font-size: 1.2rem;
	font-family: "Tilt";
    border: none;
    letter-spacing: 1px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

@media (max-width: 850px) {
    .sidebar__item-button {
        font-size: 1rem;
    }

}
@media (max-width: 400px) {
    .sidebar__item-button {
        font-size: 0.9rem;
    }
}



.sidebar__item-button:hover {
    cursor: pointer;
}

.sidebar__type-img{
    border-radius: 10px;
}

.scaled {
    transform: scale(1.1);
    transition: all 300ms;
}

@keyframes sidebar {
    0% {
        display: none;
      transform: translateX(100vh);
      }
    100% {
        display: block;
      transform: translateX(0);
      }
  }

  @keyframes list {
    0% {
      transform: translateX(200px);
      opacity: 0;
      }
    100% {
      transform: translateX(0);
      opacity: 1;
      }
  }