@font-face {
    font-family: 'Bangers';
    src: url('../../fonts/Bangers-Regular.ttf');
}

@font-face {
    font-family: "Bungee";
    src: url('../../fonts/Bungee-Regular.ttf');
}

@font-face {
    font-family: 'Fred';
    src: url('../../fonts/FrederickatheGreat-Regular.ttf');
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.digital__clock-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 10px;
  width: 90%;
  height: 100px;
  background-color: #fae1e1;
  border: 2px solid #fff;
  border-radius:8px;
  font-family: "Tilt";
}

@media (max-width: 900px) {
  .digital__clock-wrapper{
    height: 70px;
  }
}

.digital__clock{
    width: 100%;
    font-size: 1.7rem;
    text-align: center;
    letter-spacing: 3px;
}

.digital__clock-time {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
@media (max-width: 400px) {
  .digital__clock-time{
    margin-bottom: 5px;
  }
}

@media (max-width: 900px) {
  .digital__clock {
    width: 200px;
    font-size: 1.2rem;
  }
}
.only-digital {
  height: 50px;
  font-size: 25px;
  font-weight: 300;
  width: 200px;
}

@media (max-width: 800px) {
  .digital__clock{
    top: 118px;
    left: 65px;
  }
}
@media (max-width: 500px) {
  .digital__clock{
    position: static;
    justify-content: flex-start;
    padding-left: 20px;
    box-sizing: border-box;
    padding-left: 10px;
    width: 160px;
  }
}
@media (max-width: 400px) {
  .digital__clock{
    width: 150px;
    padding-left: 2px;
  }
  .only-digital {
    margin-left: 10px !important;
  }
}

.digital__clock-merdiem{
margin-right: 7px;

}

@media (max-width: 900px) {
  .clock__merdiem {
    margin-left: 5px;
  }
}

.digital__clock-minute {
    margin-right: 7px;
}

.change {
    transform: translateY(-150px);
}

