@font-face {
  font-family: "Tilt";
  src: url('../../fonts/Tilt-Neon.ttf');
}

.clock__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    row-gap: 40px;
    margin-top: 60px;
  }
  @media (max-width: 860px) {
    .clock__list{
      column-gap: 20px;
      row-gap: 20px;
    }
  }
  @media (max-width: 770px) {
    .clock__list{
      grid-template-columns: 1fr 1fr ;
    }
  }
  
  @media (max-width: 500px) {
    .clock__list{
      margin-top: 45px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
  @media (max-width: 340px) {
    .clock__list{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  .clock__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    min-width: 220px;
    box-sizing: border-box;
    border-radius: 10px;
    padding-top: 5px;
    background-color: #e3a6a6;
    box-shadow: 0 20px 30px rgba(0,0,0,0.2);
    border: 2px solid #000;
  }
  
  @media (max-width: 500px) {
    .clock__item {
      min-width: 220px;
      max-width: 220px;
    }
  
    
  }
  .clock__title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
 

  }
  
  .clock__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.9rem;
     font-family: "Tilt";
     color: #000;
     
  }
  
  @media (max-width: 800px) {
    .clock__title {
      font-size: 1.5rem;
    }
  }

  .clock__text-changed {
    position: absolute;
    z-index: 1;
    right: 10px;
    font-family: "Tilt";
    color: red;
    top: 35px;
    font-size: 1.2rem;
  }

  @media (max-width: 400px) {
    .clock__text-changed {
      top: 30px;
      right: 5px;
      font-size: 1rem;
    }
  }
  
  .clock__button-exit {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  
  .clock__button-exit-img {
    width: 100%;
    height: 100%;
  }


  
  .clock__button-wrapper {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 900px) {
    .clock__button-wrapper {
      width: 25px;
      height: 25px;
    }
  }

  
  .clock__button-edit{
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 0;
    width: 100%;
    height: 100%;
  }


    .clock__button-edit-img {
      width: 100%;
      height: 100%;
    }

  
  .clock__button-submit {
    font-size: 1rem;
    width: 100px;
    margin-top: 10px;
    font-family: "Tilt";
    background-color: #fceded;
    border: 1px solid #000;
    border-radius: 3px;
  }
  
  @media (max-width: 400px) {
    .clock__button-submit {
      font-size: 0.8rem;
      width: 80px;
      margin-top: 5px;
    }
  }
  .clock__button-edit:hover {
   cursor: pointer;
  }
  .clock__button-exit:hover {
    cursor: pointer;
   }
  
  .clock__wrapper {
    position: relative;
    width: 210px;
    height: 210px;
    margin-top: 10px;
    }
    @media (max-width: 900px) {
      .clock__wrapper{
        width: 180px;
        height: 180px;
      }
    }
    @media (max-width: 500px) {
      .clock__wrapper{
        width: 150px;
        height: 150px;
      }
    }
    @media (max-width: 400px) {
      .clock__wrapper{
        width: 140px;
        height: 140px;
      }
    }
    .clock__clockface {
      position: absolute;
      top:0;
      width: 210px;
      height: 210px;
      border: 2px solid #fcf2f8;
      border-radius: 50%;
      box-sizing: border-box;
    }
  
    @media (max-width: 900px) {
      .clock__clockface {
        width: 180px;
        height: 180px;
      }
    }
  
    @media (max-width: 500px) {
      .clock__clockface {
        width: 150px;
        height: 150px;
      }
    }
  
    @media (max-width: 400px) {
      .clock__clockface {
        width: 140px;
        height: 140px;
      }
    }
  
    .clock__day {
      letter-spacing: 1px;
      top: 95px;
      right: 50px;
      font-size: 25px;
      font-weight: 300;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  
    .clock__analog-day {
      position: absolute;
      z-index: 5;
      letter-spacing: 1px;
      top: 98px;
      right: 50px;
      font-size: 25px;
      font-weight: 300;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  
    @media (max-width: 800px) {
      .clock__analog-day {
        top: 88px;
        right: 44px;
      }
    }
  
    @media (max-width: 500px) {
      .clock__analog-day {
        font-size: 18px;
        top: 66px;
        right: 33px;
      }
    }
  
    @media (max-width: 400px) {
      .clock__analog-day {
        top: 60px;
        right: 31px;
      }
    }
  
    @media (max-width: 500px) {
      .clock__day{
        position: static;
        color: #f2f2f2;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        letter-spacing: 3px;
        margin-left: 15px;
      }
    }
  
      @media (max-width: 400px) {
      .clock__day{
        top: 155px;
        right: 15px;
      }
    }
  
  
    .react-clock {
      display: block;
      position: relative;
      width: 100% !important;
      height: 100% !important;
    }
    
    .react-clock,
    .react-clock *,
    .react-clock *:before,
    .react-clock *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    
    .react-clock__face {
      border: none;
      background-color: #eff4fb;
      border-radius: 50%;
      box-shadow: 0 -15px 15px rgba(255,255,255,0.05),
      inset 0 -10px 10px rgba(255,255,255,0.05),
      0 4px 15px rgba(0, 0, 0, 0.3),
      inset 0 4px 10px rgba(0, 0, 0, 0.3);
    }
  
    .react-clock__hand {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;
    }
    
    .react-clock__hand__body {
      position: absolute;
      background-color: #000;
      transform: translateX(-50%);
      border-radius: 1px;
      z-index: 1;
      
    }
  
    .react-clock__minute-hand {
      z-index: 1;
    }
    .react-clock__hour-hand {
      z-index: 1;
    }
    
    
    .react-clock__mark {
      display: none;
    }
  
    .react-clock__second-hand {
      z-index: 1;
    }
    
    .react-clock__second-hand__body {
      background-color: #000;
      z-index: 2;
    }

  
  .clock__button-change {
    margin-top: 15px;
    width: 40%;
    height: 30px;
    font-size: 1rem;
    border-radius: 5px;
    letter-spacing: 2px;
    border: 1px solid #000;
    background-color: #4146cc;
    color: #f2f2f2;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .clock__button-change:hover {
  cursor: pointer;
  }
  
  .clock__action-list {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 150px;
    height: 150px;
    z-index: 10;
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
  }

  .clock__action-list-digital {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 100px;
    height: 100px;
    z-index: 10;
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
  }
  @media (max-width: 500px) {
    .clock__action-list-digital {
      width: 90px;
      height: 90px;
      top: 25px;
      right: 30px;
      border-radius: 10px;
    }
  }

  .clock__action-button-digital {
    width: 100%;
    background-color: #f7dfdf;
    border: none;
    border-radius: 5px;
    font-family: "Tilt";
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 300;
    box-sizing: border-box;
    padding: 3px;
  }

  @media (max-width: 500px) {
    .clock__action-button-digital  {
      font-size: 1rem;
    }
  }

  .clock__action-button-digital:hover {
    cursor: pointer;
  }

  @media (max-width: 550px) {
    .clock__action-list {
      width: 100px;
      height: 100px;
      border-radius: 10px;
    }
  }
  
  .clock__action-item {
    width: 80%;
    
  }
  
  .clock__action-button {
    width: 100%;
    background-color: #f7dfdf;
    border: none;
    border-radius: 5px;
    font-family: "Tilt";
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: 300;
    box-sizing: border-box;
    padding: 3px;
  }
  
  @media (max-width: 550px) {
    .clock__action-button {
        font-size: 1rem;
    }
  }
  
  .clock__action-button:hover {
      cursor: pointer;
  }
  
  .clock__action-button:disabled {
    opacity: 0.4;
  }
  
  .clock__edit-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  @media (max-width: 400px) {
    /* .clock__edit-wrapper {
      flex-direction: column;
      width: 100px;
      align-items: center;
      position: absolute;
      top: 220px;
    } */
  }
  
  @media (max-width: 400px) {
    .clock__edit-wrapper-Analog {
      top: 180px;
    }
    .clock__edit-wrapper-Digital {
      top: 100px;
    }
  }


  .react-datetime-picker {
    display: inline-flex;
    position: relative;
    background-color: #fceded;
    border: 1px solid #000;
    border-radius: 5px;
  }
  
  .react-datetime-picker,
  .react-datetime-picker *,
  .react-datetime-picker *:before,
  .react-datetime-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-datetime-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  
  .react-datetime-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
    padding: 2px;
  }
  
  .react-datetime-picker__inputGroup {
    min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    padding: 0;
  }

  @media (max-width: 400px) {
    .react-datetime-picker__inputGroup{
      font-size: 0.9rem;
    }
  }
  
  .react-datetime-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
  }
  
  .react-datetime-picker__inputGroup__divider,
  .react-datetime-picker__inputGroup__leadingZero {
    display: inline-block;
  }
  
  .react-datetime-picker__inputGroup__input {
    min-width: 0.54em;
    height: calc(100% - 2px);
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  .react-datetime-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  
  .react-datetime-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  
  .react-datetime-picker__inputGroup__amPm {
    font: inherit;
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
  }
  
  .react-datetime-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  
  .react-datetime-picker__button:enabled {
    cursor: pointer;
  }
  
  .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
  .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
    stroke: #0078d7;
  }
  
  .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
    stroke: #6d6d6d;
  }
  
  .react-datetime-picker__button svg {
    display: inherit;
  }
  
  .react-datetime-picker__calendar,
  .react-datetime-picker__clock {
    z-index: 1;
  }
  
  .react-datetime-picker__calendar--closed,
  .react-datetime-picker__clock--closed {
    display: none;
  }
  
  .react-datetime-picker__calendar {
    width: 350px;
    max-width: 100vw;
  }
  
  .react-datetime-picker__calendar .react-calendar {
    border-width: thin;
  }
  
  .react-calendar {
    z-index: 10 !important;
  }
